.MDTable {
  width: 3500px;
  font-size: 0.8em;
  font-family: Verdana, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue,
    sans-serif;
  background-color: #ffffff;
}

.MDHeaderSticky {
  position: 'sticky';
  top: '0';
}

.MDTable th,
.MDTable td {
  padding: 0px 0px 0px 0px;
  border: none;
  /*    padding-top: 5px; */
  /*  background-color: #ebecfa; */
}
.MDTable tr {
  background-color: #dddcdc;
}

.MDTable thead tr th {
  /* text-shadow: 1px 1px 1px #ccc; */
  font-weight: normal;
  font-size: 1.1em;
  background-color: #002f87;
  color: #ffffff;
  height: 30px;
}

.MDTable tbody tr td {
  border-top: solid 1px #c8cacd;
  font-size: 1.2em;
  /* background-color: #FFFFFF; */
}

/* .MDTable tbody tr:nth-of-type(even) {
     background-color: #c2bfbf;
 }
 
 .MDTable tbody tr:nth-of-type(odd) {
     background-color: #c0bdbd;
 } */

.MDcontainer {
  width: 100%;
  height: 600px;
  overflow: auto;
}

.zui-table {
  border: solid 1px #ddeeee;
  border-collapse: collapse;
  border-spacing: 0;
  font: normal 11px Arial, sans-serif;
}

.zui-table thead tr th {
  background-color: #ddefef;
  border: solid 1px #ddeeee;
  color: #336b6b;
  padding: 5px;
  text-align: left;
  text-shadow: 1px 1px 1px #fff;
}

.zui-table tbody td {
  border: dotted 1px #ddeeee;
  color: #333;
  padding: 0px;
  text-shadow: 1px 1px 1px #fff;
  border-bottom: none;
  border-top: none;
}

.zui-table-rounded {
  border: none;
}

.zui-table-rounded thead th {
  background-color: #cfad70;
  border: none;
  text-shadow: 1px 1px 1px #ccc;
  color: #333;
}

.zui-table-rounded thead th:first-child {
  border-radius: 10px 0 0 0;
}

.zui-table-rounded thead th:last-child {
  border-radius: 0 10px 0 0;
}

.zui-table-rounded tbody tr td {
  border: none;
  border-top: dotted 1px #09840f;
  background-color: #eed592;
  border-bottom: none;
}

.zui-table-rounded tbody tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}

.zui-table-rounded tbody tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}

.zui-table-min {
  height: 0px;
}

.Color1 {
  color: #043363;
  background-color: #e6e8ea;
}

.btn-customColor {
  background-color: #002f87;
  color: white;
}

.lineThrough__none {
  text-decoration: line-through;
}
