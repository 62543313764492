@media print {
  .areaImprimible {
    visibility: hidden;
  }
}

.labelSize13px {
  font-size: 13px;
  padding: 5px 5px 5px 5px;
}

.modal-30w {
  width: 30%;
  max-width: none !important;
}

.modal-40w {
    width: 40%;
    max-width: none !important;
}

.modal-50w {
  width: 50%;
  max-width: none !important;
}

.modal-70w {
  width: 70%;
  max-width: none !important;
}

.modal-90w {
  width: 90%;
  /*    max-height: 90% !important; */
  max-height: 'calc(100vh - 50px)' !important;
}

.modal-innerDiv {
  /*  height: 95%; */
  max-height: 'calc(100vh - 50px)' !important;
}

.react-bootstrap-table {
  height: 250px !important;
  overflow-y: scroll;
}

.dropzone {
  background-color: #d2f9fc;
  border: 1px dashed hsl(234, 96%, 33%);
  padding: 8% 5% 10% 10%;
  border-radius: 10px;
  width: 450px;
  height: 100px;
}

.dropzoneFileManager {
  background-color: #d2f9fc;
  border: 1px dashed hsl(234, 96%, 33%);
  /* padding: 3% 5% 5% 10%;
    */
  border-radius: 10px;
  width: 750px;
  /*  height: 150px; */
  padding-top: 1em;
  text-align: center;
}

.moneyField {
  background-color: #f5ffed;
}

th,
td {
  text-align: left;
}

tr:nth-child(odd) {
  background-color: #f5f5f5;
}

.hideDiv {
  visibility: 'hidden';
  height: '0px';
  width: '0px';
}

.hideRow {
  height: 0px;
  visibility: hidden;
}

.showRow {
  padding-top: 5px;
}

.showDiv {
  height: '100px';
  overflow: auto;
}

.userModal {
  width: 50vw;
  height: 50vw;
  max-width: 50vw;
  max-height: 50vw;
  overflow: scroll;
}

.userModal2 {
  width: 1000px;
  height: 1000px;
  overflow: scroll;
}
.readyColor {
  background-color: #d4d6d2;
}
.pendingColor {
  background-color: #ffffff;
}

.hideEverything {
  visibility: 'hidden';
  display: 'none';
  background-color: #000000;
}

.showEverything {
  visibility: 'visible';
}

.customPadding {
  padding-bottom: '5px';
  padding-top: '15px';
}

.heightZero {
  height: 0px;
}

.myFlexyTable {
  width: 2000px;
  overflow: scroll;
}

.myFlexyTable tr {
  height: 10px;
}

.formatNumber {
  font-size: 20;
}

input.numbersOnly {
  font-size: '15px';
  background-color: '#F5FFED';
  border: '1px solid #f00';
}

.parent {
  position: relative;
  margin-top: 0px;
  z-index: 1;
}

.child {
  position: absolute;
  margin-top: 30px;
  width: 400px;
  height: 150px;
  background: rgb(245, 248, 248);
  border-radius: 5px 5px 5px 5px;
  padding: 2px;
  border-style: outset;
  overflow: auto;
}

.cleanLi {
  list-style-type: none;
  padding-left: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
  cursor: pointer;
}

/* li:hover {
    color: rgb(195, 221, 238);
    background-color: rgb(11, 138, 222);
    font-style: italic;
    font-family: cursive;
    font-size: 15px;
    padding-left: 0px;
   } */

.sorroundImage {
  border: 1px solid #ced4da;
}

.genericSelect {
  padding-left: 3px;
  font-size: 13px;
  font-style: italic;
  font-family: cursive;
}

.bg-RECHAZOS_PARA_CORRESPONSAL {
  background-color: #eb984e !important;
}

.bg-CORRESPONSAL_NORMAL {
  background-color: #abeb4e !important;
}

.bg-RECHAZOS_POR_CONTABILIDAD {
  background-color: #ff0000 !important;
}

.btn-uploadExcel {
  color: #e9f0e6;
  background-color: rgb(16, 101, 101);
  border-color: #5bc2c2;
}

.btn-uploadExcelCorresponsal {
  color: #a3e488;
  background-color: rgb(29, 151, 72);
  border-color: #5bc2c2;
}

.btn-uploadPDF {
  color: #dedfc5;
  background-color: rgb(199, 66, 66);
  border-color: #f40707;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f9f9f9;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}
.genericinput {
  width: 125px;
}

.divPerfiles {
  height: 550px;
  overflow: auto;
  background-color: #000000;
}

.tableAvisoCruce {
  font-family: Verdana;
  font-size: 14px;
  border: 1px solid #000000;
  background-color: #ffffff;
  width: 1000px;
}

.tableAvisoCruce,
td,
th {
  border: 1px solid black;
  height: 25px;
}

.tableAvisoCruceHeader {
  background-color: #756d6d;
  font-weight: bold;
  text-align: center;
}
.tableAvisoCrucefNormal {
  font-weight: normal;
}
.tableAvisoCruceHyF {
  text-align: center;
  border: 1px solid white;
}

.allScreen {
  background-color: #ffffff;
  height: 1330px;
}

.tdAvisoCruceWordWrap {
  word-break: break-all;
}

.textareaCR {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.colAlignRight {
  text-align: right;
}

.blueCardBorder {
  border-color: #1d6dc2;
}

.dashboardComponentFont {
  font-family: 'Kanit';
}
