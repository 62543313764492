/* .react-captcha {
    background-color: #000000;
}
 */
/* .react-captcha-icon-wrapper {
}
 */

.react-captcha-canvas{
    background-color: rgb(242, 242, 248);
    color: #fff;
}